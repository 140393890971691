const content = {
  title: 'bAV Direktversicherung',
  headvisualHeadline: 'Betriebliche Altersvorsorge (bAV) - Direktversicherung',
  uebersichtSection: {
    topline: 'Betriebliche Altersvorsorge',
    headline:
      'Direktversicherung - Der Klassiker der bAV ist weiter im Aufwind',
    paragraphs: [
      'Eine weit verbreitete Form der betrieblichen Altersversorgung ist die <strong>Direktversicherung</strong>. Bei diesem Durchführungsweg schließen Sie per Einzel- oder Gruppenvertrag eine Lebensversicherung für Ihren Arbeitnehmer ab.',
      'Versicherungsnehmer und Beitragsschuldner sind Sie als Arbeitgeber - Begünstigter ist in diesem Fall Ihr Mitarbeiter. Die Beiträge für den zusätzlichen Aufbau der Altersvorsorge können Sie ganz übernehmen oder bezuschussen. In beiden Fällen sind Ihre Ausgaben komplett als Betriebsausgabe absetzbar. Meist werden aber die Beiträge direkt durch den Arbeitnehmer gezahlt. Diese fließen dann durch Entgeltumwandlung in die Direktversicherung.'
    ]
  },
  direktversicherungSection: {
    headline: 'Für wen eignet sich eine Direktversicherung?',
    paragraphs: [
      'Die Direktversicherung eignet sich vor allem für kleinere und mittlere Unternehmen, da der Verwaltungsaufwand für den Arbeitgeber gering ist und er kein finanzielles Risiko trägt. Auch für den Arbeitnehmer ist dieser klassische Durchführungsweg der betrieblichen Altersversorgung sehr attraktiv.',
      'Die Beiträge in die Direktversicherung sind bis zu 8 Prozent der Beitragsbemessungsgrenze (BBG) der gesetzlichen Rentenversicherung (West) steuerfrei und bis zu 4 % sozialabgabefrei. Sofern ein Vertrag nach § 40b EStG a.F. pauschal besteuert wird, ist dieser Beitrag auf die 8 % der BBG anzurechnen.'
    ],
    firstCard: {
      headline: 'Wer kann eine Direktversicherung abschließen?',
      content:
        'Jeder, der über einen Dienst- oder Arbeitsvertrag angestellt ist, kann über seinen Arbeitgeber eine Direktversicherung abschließen. Hierbei muss es sich aus steuerlichen Gründen um das erste Dienstverhältnis handeln.'
    },
    secondCard: {
      headline: 'Welche Leistungen sind in einer Direktversicherung möglich?',
      items: [
        'Altersleistung ab dem 62. Lebensjahr',
        'Leistungen im Falle der Invalidität',
        'Hinterbliebenenleistung'
      ]
    }
  },
  vorteileSection: {
    headline: 'Ihre Vorteile auf einen Blick',
    items: [
      'Die Beiträge sind als Betriebsausgaben absetzbar',
      'I.d.R. keine PSV-Beiträge',
      'Sie sparen bei den Lohnnebenkosten',
      'Versicherungsvertragliches Verfahren, das bedeutet die Versorgungsverpfluchtung befindet sich nicht in Ihrem Unternehmen',
      'Es ist kein Bilanzausweis erforderlich',
      'Geringer Verwaltungsaufwand'
    ],
    bottomline:
      'Nutzen Sie die Chance und machen Sie mit dem gesetzlich verpflichtenden Arbeitgeber-Zuschuss in Höhe von 15% die Betriebsrente für Ihre Mitarbeiter noch attraktiver!'
  }
};

export default content;
