import React from 'react';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import MainLayout from 'src/layout/MainLayout';
import {
  Grid,
  SectionHead,
  ContentBox,
  HalvedContent,
  List,
  Paragraphs,
  NonStretchedImage
} from 'src/components';
import content from 'src/content/unsere-loesungen/absicherung-fuer-mitarbeiter/betriebliche-altersvorsorge/bav-direktversicherung';
import subpageNavigations from 'src/data/subpage-navigations';

const BAVDirektversicherungPage = ({ data }) => (
  <MainLayout
    title={content.title}
    headvisual={{
      image: data.headvisual,
      headline: content.headvisualHeadline,
      position: '75'
    }}
    subpageNavItems={subpageNavigations.betrieblicheAltersvorsorge}
    activeNavItem={1}
    useParentPathname
  >
    <section>
      <Grid>
        <HalvedContent swapped breakpoint="xl">
          <BackgroundImage
            fluid={data.smilingWomanAtTable.childImageSharp.fluid}
          />
          <ContentBox
            background="gray-blue"
            topline={content.uebersichtSection.topline}
            headline={content.uebersichtSection.headline}
            align="right"
          />
        </HalvedContent>

        <div className="container container--simple children-margin">
          <Paragraphs items={content.uebersichtSection.paragraphs} innerHTML />
          <NonStretchedImage
            fluid={data.direktversicherung.childImageSharp.fluid}
          />
        </div>
      </Grid>
    </section>
    <section className="no-children-margin">
      <SectionHead headline={content.direktversicherungSection.headline} gap>
        <Paragraphs items={content.direktversicherungSection.paragraphs} />
      </SectionHead>
      <HalvedContent align="center">
        <ContentBox
          background="light-grey-blue"
          headline={content.direktversicherungSection.firstCard.headline}
        >
          <p>{content.direktversicherungSection.firstCard.content}</p>
        </ContentBox>
        <BackgroundImage
          fluid={data.womanWithLumberjacksShirt.childImageSharp.fluid}
        />
      </HalvedContent>
      <HalvedContent align="center">
        <BackgroundImage fluid={data.relaxedManOnChair.childImageSharp.fluid} />
        <ContentBox
          background="light-pink"
          headline={content.direktversicherungSection.secondCard.headline}
        >
          <List items={content.direktversicherungSection.secondCard.items} />
        </ContentBox>
      </HalvedContent>
    </section>
    <section>
      <ContentBox
        background="light-blue"
        headline={content.vorteileSection.headline}
        single
      >
        <List items={content.vorteileSection.items} columns={2} />
        <p>
          <strong>{content.vorteileSection.bottomline}</strong>
        </p>
      </ContentBox>
    </section>
  </MainLayout>
);

export default BAVDirektversicherungPage;

export const query = graphql`
  {
    headvisual: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "smiling-woman-with-tablet.jpg" }
    ) {
      ...image
    }
    direktversicherung: file(
      sourceInstanceName: { eq: "illustrations" }
      relativePath: { eq: "direktversicherung.jpg" }
    ) {
      ...image
    }
    womanWithLumberjacksShirt: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "smiling-woman-with-red-lumberjack-shirt.jpg" }
    ) {
      ...image
    }
    relaxedManOnChair: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-sits-relaxed-on-a-chair.jpg" }
    ) {
      ...image
    }
    smilingWomanAtTable: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "woman-leans-at the-table-and-smiles.jpg" }
    ) {
      ...image
    }
  }
`;
